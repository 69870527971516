body {
font-family: Century gothic;
margin: 0;
box-sizing: border-box;
}

@media only screen and (max-width:768px) {
	.v-container {
		display: flex;
		flex-direction:column;
		flex: 1;
		
		color: #0d4261;
		padding: 30px 15px;
		background-color: #f0ffff;
		min-height: 150px;
	}
	.v-list-header {
		position: relative;
	  font-size: 2em;
	  font-weight: bold;
	  color: white;
	  border: 1px solid #eee;
	  margin: 0 15px;
	  padding: 50px 40px;
	  opacity: 0.5;
	  box-sizing: border-box;
	 
	}	
	.ml-100 {
		margin-left: 30px;
	}
	.ml-150 {
		margin-left: 60px;
	}
	.mb-15 {
		margin-bottom: 15px;
	}
	.v-message {
		text-align: justify;
		padding: 15px;
	}
}

@media only screen and (min-width:768px) {
	.v-container {
		display: flex;
		flex-direction:column;
		flex: 1;
		width: 100%;
		color: #0d4261;
		padding: 30px 50px;
		background-color: #f0ffff;
		min-height: 150px;
		box-sizing: border-box;
	}
	.v-list-header {	  
	  font-size: 3em;
	  font-weight: bold;
	  color: white;
	  border: 1px solid #eee;
	  margin: 0;
	  padding: 80px 60px;
	  opacity: 0.5;
	  min-height: 150px;
	}	
	.ml-100 {
		margin-left: 60px;
	}
	.ml-150 {
		margin-left: 100px;
	}
	.mb-15 {
		margin-bottom: 15px;
	}	
	.v-message {
		text-align: justify;
		padding: 30px 50px;
	}
}


@media only screen and (min-width:992px) {
	.v-container {
		display: flex;
		flex-direction:column;
		flex: 1;
		width: 100%;
		color: #0d4261;
		padding: 30px 200px;
		background-color: #f0ffff;
	}
	.v-list-header {	  
	  font-size: 5em;
	  font-weight: bold;
	  color: white;
	  border: 1px solid #eee;
	  margin: 0;
	  padding: 75px 150px;
	  opacity: 0.5;
	  box-sizing: border-box;
	}	
	.ml-100 {
		margin-left: 100px;
	}
	.ml-150 {
		margin-left: 150px;
	}
	.mb-15 {
		margin-bottom: 15px;
	}	
	.v-message {
		text-align: justify;
		padding: 30px 100px;
	}
}

.v-list-container {
	background-image: url("../img/electronics-intro.JPG");
	min-height: 100vh;	
	width: 100%;
	background-size: cover;
	display: flex;
	flex-direction: column;
	margin-bottom: 0;
	padding-bottom: 0;
	box-sizing: border-box;
	 overflow: hidden;
}
.text-left {
	text-align: left;
}
.bold {
	font-weight:bold;
}
.v-link > a {
	color: #0d4261;
	text-decoration: underline;
}
.v-link {
    padding: 5px 15px;
}