
@import url('https://fonts.googleapis.com/css2?family=Blaka&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Orbitron&display=swap');
@font-face {
  font-family: 'Montserrat';
  src: url('Montserrat-VariableFont_wght.ttf');
}
body {
	font-family: Montserrat, 'Montserrat', "Montserrat", Quicksand, sans-serif, 'Sans-Serif' !important;
	box-sizing: border-box !important;
	-webkit-user-select: none; /* Safari */        
	-moz-user-select: none; /* Firefox */
	-ms-user-select: none; /* IE10+/Edge */
	user-select: none; /* Standard */

}
select {
	flex: 1;
	margin-left: 15px; 
	border: 1px solid rgb(0, 0, 0, 0.5); 
	padding: 5px; 
	border-radius: 4px;
}
section {
	width: 100% !important!;
	padding: 0 !important;
	width: 100% !important;
	text-align: justify;
}
.scroll-container {
  scroll-snap-type: mandatory;
  scroll-snap-points-x: repeat(100vw);
  scroll-snap-type: x mandatory;
}

.scroll-container > div {
  scroll-snap-align: start;
}
.list-types {
	white-space: no-wrap;
	height: 40px;
	overflow-y: hidden;
	overflow-x: auto;
	background-color: #fff;
}
.list-types > div {
	width: auto;
	padding: 10px 15px;	
	color: #444;
	font-size: 12px;
	white-space: nowrap;
}
.select-container {
	display: flex;
	justify-content: space-between;
	margin-top: 10px;
}
.select-label {
	padding: 5px;
	width:30%;
}
.error-code {
	font-family:Blaka;
	font-size: 150px;
	display: flex;
	align-items: center;
	justify-content: center;
	width: 100%;
	flex: 1;
	color: #555;
}
.flex {
	display: flex !important;
}
.flex-center {
	display: flex !important;
	justify-content: center !important;
	align-items: center !important;
}
.ln-center {
	display: flex !important;
	justify-content: center !important;
}
.tr-center {
	display: flex !important;
	align-items: center !important;
}
.col {
	flex-direction: column !important;
}
.purple-cl {
	color: #000 !important;
}
.purple-bg {
	background-color: #000 !important;
}	
.w-100 {
	width: 100% !important;
}
.h-100 {
	height: 100% !important;
}
.ptr {
	cursor: pointer !important;
}
.mh-15 {
	margin-left: 15px !important;
	margin-right: 15px !important;
}
.rel {
	position: relative !important;
}
.abs {
	position: absolute !important;
}
.nav-image {
	overflow: hidden; 
	background-color: transparent; 
	padding: 5px;
	border-radius: 50%; 
	width: 30px;
	height: 30px; 
	max-width: 30px;
	min-width: 30px;
	max-height: 30px;
	min-height: 30px;
}
.main-header {
	background-color: orange;
	height: 40px; 
	justify-content: space-between; 
	padding: 5px 15px;
	box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 3px 0px, rgba(0, 0, 0, 0.06) 0px 1px 2px 0px;	
}
/* .main-header-anim {
	animation-name: main-header;
	animation-duration: 10s;
	animation-timing-function: cubic-bezier(1,-1, 0, 2);
	animation-iteration-count: infinite;
	-webkit-animation-name: main-header;
	-webkit-animation-duration: 10s;
	-webkit-animation-timing-function: cubic-bezier(1,-1, 0, 2);
	-webkit-animation-iteration-count: infinite;
} */
.nav {
	position: fixed; 
	z-index: 15000; 
	background-color:rgb(18, 60, 105, 0.8);
	width: 100vw;
	height: 100vh; 
	text-align: center; 
	top: 0;
	box-sizing: border-box; 
}
.e-icons-scroll {
	width: 40px;
	height: 3px;
	background-color: #ccc;
	display: flex;
	justify-content: space-around;
}
.e-icons-scroll > div {
	width: 20px;
	height: 100%;
}
.e-icons-scroll > div.e-scroller {	
	background-color: #000;
}
.side-icon {
	position: absolute;
	width: 20px;
	height: 20px;
	right: 10px;
	cursor: pointer;
}
.delete-icon {
	cursor: pointer;
	position: absolute;
	right: 35px; 
	width: 20px;
	height: 20px;
}
.button {
	cursor: pointer;
	align-self: flex-end; 
	border: 1px solid #000; 
	border-radius: 4px;
	padding: 5px 10px;
	margin-bottom: 15px;
	background-color: #000;
	color: #fff;
}	
.expanded-title {
	margin: 10px 15px 0 15px;
	padding: 5px 25px 5px 15px;
	width: calc(100% - 60px); 
	min-height: 40px; 
	font-size: 16px;
	font-weight: bold; 
	background-color: #d0d0d0; 
	border: 1px solid #ddd;
}
.dot {
	border-radius: 50%; 
	background-color: #fff;
	margin: 1px;
}
.options-dots {
	right: 10px; 
	font-weight: bold; 
	height: 14px;
	color: #222;
}
.v-menu {
	position: absolute;
	top: 0;
	bottom: 0;	
	cursor: pointer; 
	height: 70px;
	padding-bottom: 10px;
	display: flex; 
	flex-direction: column;
}
.menu-items {
	right: 0; 
	border: 1px solid #ccc;
	background-color: white; 
	padding: 5px 10px; 
}
.full-screen {
	position: absolute;
	top: 0; 
	bottom: 0; 
	left: 0; 
	right: 0; 
}
.pop-up {
	position: fixed;
	z-index: 1003; 
	left: 40%;
	top: 50%;
	background-color: #222;
	color: #fff;
	border-radius: 4px; 
	padding: 15px 30px;
}
.info-image {
	background: no-repeat center/100% url("../img/info.png");
}
.watches {
	font-weight: bold;
	font-size: 22px; 
	padding-left: 15px; 
	object-fit: cover;
	background-size: 100%;
	background-image: url("../img/watches.jpg");
	background-position: center;
	color: yellow;
	height: 150px;
	text-shadow: 2px 2px orange;
	animation-name: houses;
  animation-duration: 2.5s;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
}
.all-others {
	display: grid;
	width: 100%;
	grid-template-columns: 50% 50%;
	grid-row-gap: 30px;    
	justify-items: center;
}
.all-others > div > div:first-child {
	background-color: #ccc;
	border-radius: 50%;
	width: 80px;
	height: 80px;
	background-size: 90%;
	background-position: center;
	background-repeat: no-repeat;		
}
.all-others > div > div:nth-child(2) {
	color: #555;
	width: 100%;
	text-align: center;
	font-size: 14px;
	margin-top: 10px;
}
.all-others > div:first-child  > div:first-child{
	background-image: url("../img/health&beauty.PNG");	
}
.all-others > div:nth-child(2)  > div:first-child{
	background-image: url("../img/machineries.png");	
}
.all-others > div:nth-child(3) > div:first-child {
	background-image: url("../img/education.png");	
}
.all-others > div:nth-child(4)  > div:first-child{
	background-image: url("../img/others.png");	
}
.electronics-icons {
	display: grid;
	width: calc(100% -15px);
	margin: 15px;
	justify-items: center;
	grid-template-columns: 1fr 1fr 1fr;
	grid-row-gap: 30px;
	grid-column-gap: 15px;
	grid-auto-rows: 1fr;
	
}
.electronics-icons > div {	
	font-size: 10px;
	display: flex;
	flex-direction: column;
	align-items: center;
}
.electronics-icons > div > div:first-child {
	border-radius: 50%;
	width: 50px;
	height: 50px;
	background-color: #ccc;
	background-size: 75%;
	background-repeat: no-repeat;
	background-position: center;
}
.electronics-icons.e-icons0 > div:nth-child(1) > div:first-child {
	background-image: url("../img/electronics/Mobile.PNG");
}
.electronics-icons.e-icons0 > div:nth-child(2) > div:first-child {
	background-image: url("../img/electronics/Laptop.PNG");
}
.electronics-icons.e-icons0 > div:nth-child(3) > div:first-child {
	background-image: url("../img/electronics/Tablet.PNG");
}
.electronics-icons.e-icons0 > div:nth-child(4) > div:first-child {
	background-image: url("../img/electronics/Smart Watches.PNG");
}
.electronics-icons.e-icons0 > div:nth-child(5) > div:first-child {
	background-image: url("../img/electronics/Desktop.PNG");
}
.electronics-icons.e-icons0 > div:nth-child(6) > div:first-child {
	background-image: url("../img/electronics/Headset.PNG");
}
.electronics-icons.e-icons0 > div:nth-child(7) > div:first-child {
	background-image: url("../img/electronics/Playstation.PNG");
}
.electronics-icons.e-icons0 > div:nth-child(8) > div:first-child {
	background-image: url("../img/electronics/Camera.PNG");
}
.electronics-icons.e-icons0 > div:nth-child(9) > div:first-child {
	background-image: url("../img/electronics/Speaker.PNG");
}

.electronics-icons.e-icons1 > div:nth-child(1) > div:first-child {
	background-image: url("../img/electronics/Mobile Accessories.PNG");
}
.electronics-icons.e-icons1 > div:nth-child(2) > div:first-child {
	background-image: url("../img/electronics/Music equipment.PNG");
}
.electronics-icons.e-icons1 > div:nth-child(3) > div:first-child {
	background-image: url("../img/electronics/Network products.PNG");
}
.electronics-icons.e-icons1 > div:nth-child(4) > div:first-child {
	background-image: url("../img/electronics/Computer Accessories.PNG");
}
.electronics-icons.e-icons1 > div:nth-child(5) > div:first-child {
	background-image: url("../img/electronics/TV.png");
}
.electronics-icons.e-icons1 > div:nth-child(6) > div:first-child {
	background-image: url("../img/electronics/Software.PNG");
}
.electronics-icons.e-icons1 > div:nth-child(7) > div:first-child {
	background-image: url("../img/electronics/Printer.PNG");
}
.electronics-icons.e-icons1 > div:nth-child(8) > div:first-child {
	background-image: url("../img/electronics/Home Appliances.PNG");
}
.electronics-icons.e-icons1 > div:nth-child(9) > div:first-child {
	background-image: url("../img/electronics/Others.PNG");
}


.kids, .men {	
	background-repeat: no-repeat;
	background-size: cover;
	height: 480px;
	width: 25vw;
	margin-bottom: 15px;
	position: relative;
	display: none;
}
.kids > div, .men > div {
	position: absolute;
	bottom: 60px;
	right: 60px;
	height: 40px;
	width: auto;
	color: yellow;	
	font-size: 24px;
	cursor: pointer;
	font-style: italic;
	text-decoration: underline;
}
.grid-1b2.electronics-list {
	grid-template-columns: repeat(10, 1fr);
	overflow-x: auto;
	white-space: nowrap;
	overflow-y: hidden;
	height: 220px;
}
.grid-1b2.shopitems {
	grid-template-columns: repeat(10, 1fr);
	overflow-x: auto;
	white-space: nowrap;
	overflow-y: hidden;
	height: auto;
	max-height: 300px;
}
.grid-1b2.shopitems > div > img {
	height: 95%;
}
.grid-2b2.items-list {
	height: auto !important;
}
.grid-2b2.items-list > div > img, .grid-2b2.items-list > div > video, .grid-2b2.items-list > div > div.info-article {
	height: 80% !important;
}
.grid-2b2.items-list > div  {
	height: 240px !important;
}
.grid-1b2.electronics-list > div {
	width: 40vw;
	min-width: 40vw;
	height: 80%;
	max-height: 165px;
}
.grid-1b2.grid-1b3.electronics-list > div {
	width: calc(33.33vw - 20px);
	min-width: calc(33.33vw - 20px);
	height: 80%;
	max-height: 165px;
}
.grid-1b2.shopitems > div {
	width: 40vw;
	min-width: 40vw;
	height: 85%;
	box-sizing: border-box;
}
.grid-2b2.with-title {
	height: 410px;
}
.grid-2b2.others {
	height: 650px;
}
.grid-2b2 {
	display: grid;
	grid-template-columns: 1fr 1fr;
	grid-auto-rows: 1fr;
	height: 390px;
	box-sizing: border-box;
}
.grid-2b2.grid-1b3 {
	grid-template-columns: repeat(1fr, 10);
}
.grid-1b2 {
	height: 210px;
}
.grid-2b2.grid-1b3 > div {
	position: relative;
	width: calc(100% - 15px);
	height: 165px;
	background-color: #fff;
	padding: 5px;
}
.grid-2b2.cultural > div {
	width: calc(100% - 15px);
	padding: 5px;
}
.grid-2b2 > div {
	position: relative;
	width: calc(100% - 30px);
	height: 165px;
	background-color: #fff;
	padding: 15px 15px 15px 10px;
}
.grid-2b2 > div.info-image {
	width: calc(100% - 30px);
	padding: 15px;
}

.grid-2b2 > div > img, .grid-2b2 > div > video, .grid-2b2 > div > span {
	object-fit: cover;
	width: 100%;
	height: 95%;
	background-color: #ccc;
}
.grid-2b2 > div > div {
	color: #000;
	font-size: 12px;	
	border-radius: 7px;
	width: 60px;	
	left: 5px;	
	bottom: 5px;
}
.home-grid-border {
	border: 1px solid #ddd;
	border-radius: 0 !important;
	box-sizing: border-box;
	padding: 2px 3px; 
	margin-top: -5px;
}
.info-cover {
	 background-color: #555 !important;
	 color: #eee !important;
	 font-size: 24px !important;
	 white-space: break-spaces !important;
}
.see-more {
	font-size: 12px; 
	color: rgb(0, 0, 0, 0.5);
	text-align: right;
	padding: 5px 15px;
}
.expanded-content {
	align-self: flex-start;
	font-size: 14px;
	border-radius: 3px; 
	width: calc(100% - 50px); 
	
	margin: 15px 10px; 
	padding: 5px 15px;
}
.pic-slider {
	position: absolute; 
	z-index: 101; 
	color: #ccc;
	left: 0;
	right: 0;
	height: 50px;
}
.icon-md {
	width: 50px;
	height: 50px; 
	margin: 10px;
}
.radio {
	padding: 2px; 
	width: 16px; 
	height: 16px; 
	margin-right: 10px; 
	border-radius: 50%; 
	background-color: #fff; 
	border: 1px solid rgb(0, 0, 0,0.4);
}
.radio > div {
	width: 12px;
	height: 12px;
	border-radius: 50%;	
	background-color: rgb(0, 0, 0,0.8);
}
.view-more {
	cursor: pointer; 
	color: #555; 
	padding: 5px; 
	font-size: 12px; 
	width: 100%; 
	text-align: center; 
	margin-bottom: 15px;
}
.list-button {
	padding: 2px;
	max-width: 50%; 
	color: #fff;
	width: 80px;
	height: 25px;
	font-size: 14px;
}
.home-bg {
	position: relative;
	display: flex;
	align-items: flex-end; 
	text-align: justify;
	flex: 1;
	border: none;
	cursor: pointer;
	justify-content: center;
	align-Items: flex-end;
	background-repeat: no-repeat;
	overflow: hidden;
	object-fit: contain;
}
.bg {
	position: relative;
	display: flex;
	align-items: flex-end; 
	text-align: justify;
	flex: 1;
	border: none;
	cursor: pointer;
	justify-content: center;
	align-Items: flex-end;
	background-repeat: no-repeat;
	height: 100%;
	max-height: 200px;
	width: 50%; 
	overflow: hidden;
	object-fit: contain;
	padding: 10px 0;
	background-color: #fff;
}
.list-title {
	font-size: 14px; 
	color: #29648a;
	font-weight: bold;
}
.list-content {
	flex: 1; 
	font-size: 12px; 
	color: #29648a;
	padding: 5px 10px; 
	text-align: justify;
}
.bg-icon {
	width: 108px;
	height: 108px;
	background-color: transparent;
	background-repeat: no-repeat;
	object-fit: cover;
	background-size: 100%;
	background-image: url("../img/logo-outline.svg");
	background-position: center;
}
.bg-pic {
	background-size: 100%;
	background-color: #fff;
}
.clothing-options {
	 height: 40px;
	overflow-x: auto;
	white-space: no-wrap;
	width: 100%;
	margin-top: 15px;
}
.clothing-options > div {
	padding: 0 15px;
	display: flex;
	justify-content: center;
}
.form-bg {
	position: fixed; 
	top: 0;
	z-index: 1001;
	display: flex; 
	justify-content: center; 
	width: 100%;
	max-height: 100vh;
		min-height: 100vh;
	overflow-y: scroll;
	-webkit-overflow-scrolling: touch;
}
.expanded-container {
	position: fixed; 
	top: 0; 
	z-index: 1001;
	box-sizing: border-box;
	min-height: 100vh;
	max-height: 100vh;
	height: 100vh;
    min-height: -webkit-fill-available;
	max-height: -webkit-fill-available;
	height: -webkit-fill-available;
	overflow-y: scroll; 
	background-color: rgb(36, 48, 94, 0.9);
	-webkit-overflow-scrolling: touch;
}
.details-menu {
	z-index: 10; 
	background-color: #fff; 
	position: absolute; 
	flex: 1; 
	margin-left: 15px; 
	border: 1px solid rgb(0, 0, 0, 0.2); 
	padding: 5px; 
	border-radius: 4px; 
	font-family: Montserrat; 
	color: rgb(0, 0, 0, 0.9); 
	font-size: 14px; 
	font-weight: 400;
}
.highlight {
	position: absolute;
	left: 0; 
	right: 0;
	top: 0;
	z-index: 100;
	background-color: rgb(0, 0, 0, 0.5);
}
div.clicked > img {
	width: 100%;
	height: 100%;
	object-fit: contain;
}
div.clicked {
		position: absolute;
		top: 0;
		bottom: 0;
		left: 0;
		right: 0;
		max-width: 400px;
		width: auto;
		height: auto;
		margin: 0;
		background-color: #fff;
	}	
.home-arrow {
	position: absolute;
	top: 0;
	bottom: 0;	
	width: 40px;
	min-width: 40px;												
	z-index: 1001;
}
.home-arrow > div {
	background-color: rgb(0,0,0,0.3);
	color: #fff; 
	padding: 5px;
	width: 100%;
	height: 35px !important;
}
.intro-images.others {
	background-image: url("../img/others-intro.png");
	background-size: cover;
	animation-name: electronics;
	
}
.intro-images.electronics {
	background-image: url("../img/electronics-intro.JPG");
	background-size: cover;
	animation-name: electronics;
	
}
.intro-images.shops {
	background-image: url("../img/shops.png");
	background-size: cover;
	animation-name: "";
	animation-duration: 1.5s;
	color: orange;
	text-shadow: 1px 1px #000;
}

.intro-images {
	background-image: url("../img/electronics.webp");
	background-repeat: no-repeat;
	background-size: contain;
	background-color: rgb(0, 0, 0,0.7);
	background-position: center; 
	padding: 15px;
	text-align: center;	
	width: calc(100% - 30px);
	height: 15vh;
	max-height: 500px;
	color: #fff;
	font-size: 32px;
	font-weight: bold;
	text-shadow: 2px 2px orange;
	animation-name: electronics;
  animation-duration: 2.5s;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
  
}
@keyframes electronics {
  from {color: transparent;text-shadow: none;}
  to {color: yellow;text-shadow: 2px 2px orange;}
}
@keyframes houses {
  from {color: transparent;text-shadow: 2px 2px #000;}
  to {color: yellow;text-shadow: 2px 2px #000;}
}
@keyframes home-image {
  0% {left: 0;}
  25% {left: 0;}
  50% {left: -100vw;z-index:100;}
  75% {left: 100vw;z-index:100;}
  100% {left: 0;z-index: 101;}
}
@keyframes home-image2 {
  0% {left: 100vw;}
  25% {left: 100vw;}
  50% {left: 0;z-index:101;}
  75% {left: 0;z-index:101;}
  100% {left: -100vw;z-index: 100;}
}

@keyframes main-header {
  from {background-color: orange;}
  to {background-color: #0fb5c0;}
}
@keyframes shops {  
  0%   {color: transparent;text-shadow: none;}
  25%  {color: transparent;text-shadow: none;}
  50%  {color: yellow;text-shadow: none;}
  75%  {color: yellow;text-shadow: 2px 2px #000;}
  100% {color: yellow;text-shadow: 2px 2px #000;}
}
@media only screen and (max-width:480px) {
	.home-container {
		width: 100vw;
		min-height: 100vh;
		display: flex;
		box-sizing: border-box;		
		flex-direction: column;	
		background-color: #fff;
	}
	::-webkit-scrollbar {
			display:none;
		}
	.form::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: rgb(0, 0, 0, 0.6);
  opacity: 1; /* Firefox */
}

.form:-ms-input-placeholder { /* Internet Explorer 10-11 */
  color: rgb(0, 0, 0, 0.6);
}

.form::-ms-input-placeholder { /* Microsoft Edge */
  color: rgb(0, 0, 0, 0.6);
}
	.header {
		position: sticky;
		top: 200px;
		z-index: 2;
		background-color: #f0ffff;
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		align-items: center;		
		margin-bottom:5px;
		box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;

	}
	.home-message {
		padding: 10px 0 0 0;
		width:100%;
		background-color: #000;
		background-repeat: no-repeat;
		background-size: cover;
	}
	.home-text {
		font-size: 2em;
		font-weight: bold;
		color: #90ccf4;
		padding-left: 50px;
	}
	img.bg {
		background-color: transparent;
	}
	.intro-images {
		font-size: 26px;
	}
	.deals-container {		
		padding: 0 0 15px 15px;		
		box-sizing: border-box;
	}
	.deals-category {
		width: 100%;		
		margin: 15px 0;
		background-color: #fff;
		display: flex;
		flex-direction: column;
	}
	.deals-grid {
		padding: 5px;
		height: 260px; 
	}
	.home-bg, .highlight {
		height: 75% !important;
	}
	.deals-grid > div > div {
		height: 25%;
	}
	
	.deals-grid > div.home-activity {
		width: calc(50vw - 49px);
		min-width: calc(50vw - 49px);
		max-width: calc(50vw - 49px);
		height: 
	} 
	.pic-protype {
		width: 16px;
		height: 16px;
		border-radius: 50%;
		background-color: #000;
		margin-left: 10px;
		background-repeat: no-repeat;
		background-size: 100%;		
	}
	.dot {
		width: 2px;
		height: 2px;
	}
	.features {		
		padding: 15px;
		background-color: #f0ffff;
	}
	.category {
		height: 50vh;
		width: 100%;
		position: relative;	
		border: 1px solid #ccc;
		border-radius: 4px;
		box-shadow: #64485c 1.95px 1.95px 2.6px;
		 display: flex;
		 flex-direction: column;
		 align-items: center;
		 justify-content:space-between;
		 overflow: hidden;
		 text-align: center;
	}
	.logo {
		width: 40px;
		height: 24px;
		padding: 0;
		cursor: pointer;
	}
	.search {
		padding: 10px 15px 5px 15px;
		position: relative;
		display: flex;
		align-items: center;
		color: #f0ffff;
		flex: 1;
	}
	.search > input {
		border-radius: 4px;
		width: 100%;
		height: 30px;		
		border: 0.5px solid #e9e9e9;
		color: #f0ffff;
		padding: 0 15px;
		box-sizing: border-box;
		box-shadow: rgba(115, 149, 174, 0.2) 0px 2px 8px 0px;
	}
	
	.search > svg {
		position: absolute;
		color: #aaa;
		font-weight: bold;
		width: 20px;
		height: 20px;
		padding: 5px;
		right: 20px;		
	}
	#home-message {
		display: flex; 
		padding: 15px 0 0;
		box-sizing: border-box;
	}
	.side-menu {
		display: none;
	}
	#home-message > div.home-pic {
		flex: 1;
		height: 25vh;
		padding: 0 15px;
	}
	.list-container {
		width: 100%;
		padding-right: 15px;
		box-sizing: border-box;
		display: grid;
		grid-template-columns: 50% 50%;
		grid-column-gap: 15px;
		grid-row-gap: 15px;
	}
	.list-picture {
		height: 50vw;
		box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;		
	}
	.list-container {
		width: 100%;
		padding-right: 15px;
		box-sizing: border-box;
		display: grid;
		grid-template-columns: 1fr 1fr 1fr 1fr;
		grid-column-gap: 30px;
		grid-row-gap: 30px;
	}
	.list-picture {
		height: 25vw;
		box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
		
	}
	.list-picture-expanded {
		max-width: 100%;
		height: 330px;
		min-height: 230px;		
		box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;		
		box-sizing: border-box;
		object-fit: contain;
	}
	.cat-list-container {
		display: flex;
		height: 100%;
		width: 100%;		
		box-sizing: border-box;
	}	
	.cat-title {
		width: calc(100% - 30px);
		padding: 15px 30px;
		margin: 5px 15px 25px 15px;
		font-weight: bold;
		border-radius: 4px;
		font-size: 26px;
		box-sizing: border-box;
		background-color: rgb(128, 43, 177, 0.7);
		color: #fff;
		box-sizing: border-box;
	}
	.list-city {
		display: none;
	}
	.cat-list {
		display: flex;
		flex-direction: column;
		padding: 10px;
		border-radius: 5px;
		margin: 15px;
		background-color: #ffffff;
		border: 1px solid rgb(128, 43, 177,0.2);
		box-sizing: border-box;
	}
	.home-ad {
		width: 100%;
		grid-column: 1/3;
		height: 225px;
		background-color: #fff;
	}
	.list-ad {
		display: none;
	}
	
	
	.titles {
		background-color: #425664;
		width: 100%;
		padding: 5px 15px;
		color: #cae7df;
		font-size: 2em;
		font-weight: bold;
		box-sizing: border-box;
		opacity: 0.95;
		display: flex;
		justify-content: center;
		text-align: center;
	}
	.description-icon {
	width: 20px; 
	height: 20px;
	margin: 10px;
}
	.form-container {		
		z-index: 5; 
		background-color: #ccc; 
		font-family: Montserrat; 
		max-width: 400px; 
		width: 100vw;		
		display: flex; 
		flex-direction: column; 
		justify-content: space-between; 
		padding: 15px 15px 30px 15px; 
		box-sizing: content-box !important;
	}
	.form-message {
		font-size: 22px;
	}
	.category > img {
		 height: 90%;
		 width: 90%;
		 overflow: hidden;
		
	}
.circle {
  margin: auto;
  height: 100px;
  width: 100px;
  border-radius: 100%;
  background: #4E8953;
  margin-top: 150px;
}

h1 {
  color: #fdd935;
  text-align: center;
  line-height: 35px; 
  -moz-transform: scale(1) rotate(-5deg) translateX(0px) translateY(0px) skewX(1deg) skewY(1deg);
-webkit-transform: scale(1) rotate(-5deg) translateX(0px) translateY(0px) skewX(1deg) skewY(1deg);
-o-transform: scale(1) rotate(-5deg) translateX(0px) translateY(0px) skewX(1deg) skewY(1deg);
-ms-transform: scale(1) rotate(-5deg) translateX(0px) translateY(0px) skewX(1deg) skewY(1deg);
transform: scale(1) rotate(-5deg) translateX(0px) translateY(0px) skewX(1deg) skewY(1deg);
  /*-----------------------*/
  text-shadow: 1px 1px #646c79, 2px 2px #646c79;
  
}
.desc-text {
	 font-size: 18px;
	 font-weight: bold;
	 opacity: 0.6;
	 background-color: #D5E2D6;
	 color: #4a744d;
}
.desc-text.city {
	text-align: right;
	font-size: 10px;
	padding: 5px 15px;
}
h1 {
	margin-block-start: 0;
}

	.label {
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		padding: 5px;
		font-size: 22px;
		background-color: #000000;
		background-clip: text;
  color: transparent;


		font-weight: bold;
	}	
	.footer {
        background: #555;
        width: 100%;
        display: flex;
		display: -webkit-flex;
		display: -webkit-flexbox;
		flex-direction: column;
		-webkit-flex-direction: column;
        justify-content: space-evenly;
        align-items:stretch;
        flex-wrap: wrap;
		-webkit-flex-wrap: wrap;
        margin: 0;
        padding: 10px 10px 15px 10px;
		margin-top: 15px;
		box-sizing: border-box;
    }
    div.footer > div:first-child {
        flex: 1;
		-webkit-flex: 1;
        margin-top: 10px;
        flex-basis: 100%;
		font-size: 14px;
    }
	.footer-contents > div {		
		padding-top: 30px;
	}
	.footer-contents > div:first-child, .footer-contents > div:nth-child(2) {
		display: flex;
		flex-direction: column;	
		text-align: center;
	}
	.footer-grid {
		display: grid;
		grid-template-columns: 1fr;		
	}
	.footer-grid> :nth-child(odd) {
		display: none;
	}
	.footer-grid> :nth-child(even) {
		color: white;	
		text-align: center;
		font-style: italic;
	}
	
    li > a {
        color: white;
        text-decoration: none;
    }
	.footer-contents > ul > li {
		list-style-type: none;
		text-align: left;
	}
	.feedback {
		display: flex;
		flex-direction: column;
		width: 95%;
		padding: 15px;
		box-sizing: border-box;
	}
}
@media only screen and (min-width: 480px) and (max-width: 768px) {
	.home-container {
		width: 100vw;
		min-height: 100vh;
		display: flex;
		box-sizing: border-box;		
		flex-direction: column;	
		background-color: #fff;
	}
	::-webkit-scrollbar {
			display:none;
		}
	.form::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: rgb(128, 43, 177, 0.5);
  opacity: 1; /* Firefox */
}

.form:-ms-input-placeholder { /* Internet Explorer 10-11 */
  color: rgb(128, 43, 177, 0.5);
}

.form::-ms-input-placeholder { /* Microsoft Edge */
  color: rgb(128, 43, 177, 0.5);
}
	.header {
		position: sticky;
		top: 200px;
		z-index: 2;
		background-color: #f0ffff;
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		align-items: center;		
		margin-bottom:5px;
		box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;

	}
	.home-message {
		padding: 10px 0 0 0;
		width:100%;
		background-color: #000;
		background-repeat: no-repeat;
		background-size: cover;
	}
	.home-text {
		font-size: 2em;
		font-weight: bold;
		color: #90ccf4;
		padding-left: 50px;
	}
	img.bg {
		background-color: transparent;
	}
	.intro-images {
		font-size: 26px;
	}
	.deals-container {		
		padding: 0 0 15px 15px;		
		box-sizing: border-box;
	}
	.deals-category {
		width: 100%;		
		margin: 15px 0;
		background-color: #fff;
		display: flex;
		flex-direction: column;
	}
	
	.deals-grid {
		padding: 5px;
		height: 270px; 
	}
	.home-bg, .highlight {
		height: 75% !important;
	}
	.deals-grid > div > div {
		height: 25%;
	}
	 .deals-grid > div.home-activity {
		width: calc(33.33vw - 48px);
		min-width: calc(33.33vw - 48px);
		max-width: calc(33.33vw - 48px);
		height: 
	} 
	.pic-protype {
		width: 16px;
		height: 16px;
		border-radius: 50%;
		background-color: #000;
		margin-left: 5px;
		background-repeat: no-repeat;
		background-size: 100%;		
	}
	.dot {
		width: 3px;
		height: 3px;
	}
	.features {		
		padding: 15px;
		background-color: #f0ffff;
	}
	.category {
		height: 50vh;
		width: 100%;
		position: relative;	
		border: 1px solid #ccc;
		border-radius: 4px;
		box-shadow: #64485c 1.95px 1.95px 2.6px;
		 display: flex;
		 flex-direction: column;
		 align-items: center;
		 justify-content:space-between;
		 overflow: hidden;
		 text-align: center;
	}
	.logo {
		width: 40px;
		height: 25px;
		padding: 0;
		margin-left: 15px;
		cursor: pointer;
	}
	.search {
		padding: 10px 30px 5px 30px;
		position: relative;
		display: flex;
		align-items: center;
		color: #f0ffff;
		flex: 1;
	}
	.search > input {
		border-radius: 4px;
		width: 100%;
		height: 30px;		
		border: 0.5px solid #e9e9e9;
		color: #f0ffff;
		padding: 0 15px;
		box-sizing: border-box;
		box-shadow: rgba(115, 149, 174, 0.2) 0px 2px 8px 0px;
	}
	
	.search > svg {
		position: absolute;
		color: #aaa;
		font-weight: bold;
		width: 20px;
		height: 20px;
		padding: 5px;
		right: 35px;		
	}
	#home-message {
		display: flex; 
		padding: 15px 0 0;
		box-sizing: border-box;
	}
	.side-menu {
		display: none;
	}
	#home-message > div.home-pic {
		flex: 1;
		height: 25vh;
		padding: 0 15px;
	}
	.list-container {
		width: 100%;
		padding-right: 15px;
		box-sizing: border-box;
		display: grid;
		grid-template-columns: 50% 50%;
		grid-column-gap: 15px;
		grid-row-gap: 15px;
	}
	.list-picture {
		height: 50vw;
		box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
		
	}
	.list-container {
		width: 100%;
		padding-right: 15px;
		box-sizing: border-box;
		display: grid;
		grid-template-columns: 1fr 1fr 1fr 1fr;
		grid-column-gap: 30px;
		grid-row-gap: 30px;
	}
	.list-picture {
		height: 25vw;
		box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
		
	}
	.list-picture-expanded {
		height: 330px;
		min-height: 230px;		
		box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;		
		box-sizing: border-box;
		
	}	
	.cat-list-container {
		display: flex;
		height: 100%;
		width: 100%;		
		box-sizing: border-box;
	}	
	.cat-title {
		width: calc(100% - 30px);
		padding: 15px 30px;
		margin: 5px 15px 25px 15px;
		font-weight: bold;
		border-radius: 4px;
		font-size: 26px;
		box-sizing: border-box;
		background-color: rgb(128, 43, 177, 0.7);
		color: #fff;
		box-sizing: border-box;
	}
	.pic-city {
		display: none;
	}
	.cat-list {
		display: flex;
		flex-direction: column;
		padding: 10px;
		border-radius: 5px;
		margin: 15px;
		background-color: #ffffff;
		border: 1px solid rgb(128, 43, 177,0.2);
		box-sizing: border-box;
	}
	.home-ad {
		width: 100%;
		grid-column: 1/3;
		height: 225px;
		background-color: #fff;
	}
	
	.list-ad {
		width: 200px;
		margin-left: 15px;
		min-height: calc(75vh - 10px);
		background-color: rgb(128, 43, 177, 0.2);
		border-top-right-radius: 4px;
		border-top-left-radius: 4px;
	}
	
	div.list-ad:nth-of-type(3) {
		display: none;
	}
	.titles {
		background-color: #425664;
		width: 100%;
		padding: 5px 15px;
		color: #cae7df;
		font-size: 2em;
		font-weight: bold;
		box-sizing: border-box;
		opacity: 0.95;
		display: flex;
		justify-content: center;
		text-align: center;
	}
.description-icon {
	width: 24px; 
	height: 24px;
	margin: 10px;
}
	.form-container {
		z-index: 5;
		margin: 30px 15px; 
		background-color: #ccc; 
		font-family: Montserrat; 
		max-width: 400px; 
		width: 100vw;		 
		display: flex; 
		flex-direction: column; 
		justify-content: space-between; 
		padding: 15px 15px 30px 15px; 
		box-sizing: content-box !important;
	}
	.form-message {
		font-size: 22px;
	}
	.category > img {
		 height: 90%;
		 width: 90%;
		 overflow: hidden;
		
	}
.circle {
  margin: auto;
  height: 100px;
  width: 100px;
  border-radius: 100%;
  background: #4E8953;
  margin-top: 150px;
}

h1 {
  color: #fdd935;
  text-align: center;
  line-height: 35px; 
  -moz-transform: scale(1) rotate(-5deg) translateX(0px) translateY(0px) skewX(1deg) skewY(1deg);
-webkit-transform: scale(1) rotate(-5deg) translateX(0px) translateY(0px) skewX(1deg) skewY(1deg);
-o-transform: scale(1) rotate(-5deg) translateX(0px) translateY(0px) skewX(1deg) skewY(1deg);
-ms-transform: scale(1) rotate(-5deg) translateX(0px) translateY(0px) skewX(1deg) skewY(1deg);
transform: scale(1) rotate(-5deg) translateX(0px) translateY(0px) skewX(1deg) skewY(1deg);
  /*-----------------------*/
  text-shadow: 1px 1px #646c79, 2px 2px #646c79;
  
}
.desc-text {
	 font-size: 18px;
	 font-weight: bold;
	 opacity: 0.6;
	 background-color: #D5E2D6;
	 color: #4a744d;
}
.desc-text.city {
	text-align: right;
	font-size: 10px;
	padding: 5px 15px;
}
h1 {
	margin-block-start: 0;
}

	.label {
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		padding: 5px;
		font-size: 22px;
		background-color: #000000;
		background-clip: text;
  color: transparent;


		font-weight: bold;
	}	
	.footer {
       background: #555;
        width: 100%;
        display: flex;
		display: -webkit-flex;
		display: -webkit-flexbox;
		flex-direction: column;
		-webkit-flex-direction: column;
        justify-content: space-evenly;
        align-items:stretch;
        flex-wrap: wrap;
		-webkit-flex-wrap: wrap;
        margin: 0;
        padding: 10px 10px 15px 10px;
		margin-top: 15px;
		box-sizing: border-box;
    }
    div.footer > div:first-child {
        flex: 1;
		-webkit-flex: 1;
        margin-top: 10px;
        flex-basis: 100%;
		font-size: 14px;
		width: 100%;
    }
	.footer-contents > div {		
		padding-top: 30px;
	}
	.footer-contents > div:first-child, .footer-contents > div:nth-child(2) {
		display: flex;
		flex-direction: column;	
		text-align: center;
	}
	.footer-grid {
		display: grid;
		grid-template-columns: 1fr;		
	}
	.footer-grid> :nth-child(odd) {
		display: none;
	}
	.footer-grid> :nth-child(even) {
		color: white;	
		text-align: center;
		font-style: italic;
	}   
	.feedback {
		display: flex;
		flex-direction: column;
		width: 100%;
		padding: 15px;
		box-sizing: border-box;
	}
}
@media only screen and (min-width: 600px) and (max-width: 768px) {
	.footer-contents {
		display: grid;
		grid-template-columns: 1fr 1fr;
		justify-items: center;
	}
	.footer-contents > div:nth-child(3) {
		grid-column: 1/3;
		width: 100%;
		display: flex;
		justify-content: center;
	}
	.feedback {
		width: 50%;
	}
	.deals-container {
		
		grid-template-columns: 50% 50%;
		grid-gap: 15px;
		padding: 0 0 15px 15px;	
		box-sizing: border-box;
		width: calc(100% - 15px);
		max-width: 100%;
	}
	.deals-grid {
		padding: 5px;
		height: 300px; 
	}
	.home-bg, .highlight {
		height: 80% !important;
	}
	.deals-grid > div > div {
		height: 20%;
	}
	.deals-grid > div.home-activity {
		width: calc(33.33vw - 48px);
		min-width: calc(33.33vw - 48px);
		max-width: calc(33.33vw - 48px);
		
	} 
	.pic-protype {
		width: 16px;
		height: 16px;
		border-radius: 50%;
		background-color: #000;
		margin-left: 5px;
		background-repeat: no-repeat;
		background-size: 100%;		
	}
	.dot {
		width: 4px;
		height: 4px;
	}
}
@media only screen and (min-width:768px) and (max-width: 1024px){
	.fullscreen {
		width: 100vw;
		height: 100vh;
		display: flex;
		justify-content: center;
		align-items: center;
		background-color: #fff;
	}
	.header {
		position: sticky;
		top: 200px;
		z-index: 2;
		background-color: #f0ffff;
		display: flex;
		flex-direction: column;
		justify-content: flex-end;
		align-items: center;		
		margin-bottom:5px;
		box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;

	}
	.logo {
		width: 50px;
		height: 32px;
		padding: 0;
		margin-left: 30px;
		cursor: pointer;
	}
	.search {
		padding: 10px 30px 5px 30px;
		position: relative;
		display: flex;
		align-items: center;
		color: #f0ffff;
		width: 70%;
	}
	.search > input {
		border-radius: 4px;
		width: 100%;
		height: 30px;		
		border: 0.5px solid #e9e9e9;
		color: #f0ffff;
		padding: 0 15px;
		box-sizing: border-box;
		box-shadow: rgba(115, 149, 174, 0.2) 0px 2px 8px 0px;
	}
	
	.search > svg {
		position: absolute;
		color: #aaa;
		font-weight: bold;
		width: 20px;
		height: 20px;
		padding: 5px;
		right: 35px;		
	}
	#home-message {
		display: flex; 
		padding: 15px 30px;
		box-sizing: border-box;
	}
	.side-menu {
		border: 1px solid #aaa;
		border-radius: 5px;
		padding: 10px 30px;
		width: 200px;
		max-height: 405px;
		overflow: hidden;
	}
	.electronics-items {
		margin: 15px;
		width: calc(75vw - 120px);
	}
	#home-message > div.home-pic {
		flex: 1;
		padding: 0 0 0 30px;
	}
	.kids {
		display: none !important;
	}
	.deals-container {	
		padding: 15px 0 15px 30px;
		box-sizing: border-box;
		width: calc(100% - 30px);
		max-width: 100%;
	}
	.deals-grid {
		padding: 5px;
		height: 320px; 
	}
	.home-bg, .highlight {
		height: 80% !important;
	}
	.deals-grid > div > div {
		height: 20%;
	}
	 .deals-grid > div.home-activity {
		width: calc(33.33vw - 51px);
		min-width: calc(33.33vw - 51px);
		max-width: calc(33.33vw - 51px);		 
	} 
	.pic-protype {
		width: 16px;
		height: 16px;
		border-radius: 50%;
		background-color: #000;
		margin-left: 5px;
		background-repeat: no-repeat;
		background-size: 100%;		
	}
	.dot {
		width: 4px;
		height: 4px;
	}
	.list-container {
		width: 100%;
		padding-right: 15px;
		box-sizing: border-box;
		display: grid;
		grid-template-columns: 1fr 1fr 1fr 1fr;
		grid-column-gap: 30px;
		grid-row-gap: 30px;
	}
	.deals-container > .home-ad {
		grid-column: 1/3 !important;
	}
	.list-picture {
		height: 25vw;
		box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
		
	}
	.list-picture-expanded {
		height: 400px;
		min-height: 300px;
		margin: 15px;
		box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
	}
	.cat-list-container {
		display: flex;
		height: 100%;
		width: 100%;
		padding: 0 30px;
		box-sizing: border-box;
	}
	.cat-list-category {
		 flex: 1;
		 padding: 0 30px;
	}
	.deals-category {
		padding: 0 10px 10px;		
		background-color: #fff;
		display: flex;
		flex-direction: column;
		border-radius: 3px;		
		box-sizing: border-box;
	}
	.deals-category:last-of-type {
		grid-column: 1 / -1;
		width: 100%;
	}
	.cat-title {
		width: calc(100% - 30px);
		padding: 15px 30px;
		margin: 5px 15px 25px 15px;
		font-weight: bold;
		border-radius: 4px;
		font-size: 26px;
		box-sizing: border-box;
		background-color: rgb(128, 43, 177, 0.7);
		color: #fff;
		box-sizing: border-box;
	}
	.pic-city {
		display: none;
	}
	.cat-list {
		display: flex;
		flex-direction: column;
		padding: 10px;
		border-radius: 5px;
		margin: 25px 15px;
		background-color: #ffffff;
		border: 1px solid rgb(128, 43, 177,0.2);
	}
	.home-ad {
		width: 100%;
		grid-column: 1/4;
		height: 225px;
		background-color: #fff;
	}
	.list-ad {
		width: 200px;
		min-height: calc(75vh - 10px);
		background-color: rgb(128, 43, 177, 0.2);
		border-top-right-radius: 4px;
		border-top-left-radius: 4px;
	}
	.description-icon {
	width: 30px; 
	height: 30px;
	margin: 10px;
}
	.form-container {
		z-index: 5; 
		background-color: #ccc; 
		font-family: Montserrat; 
		margin: 30px 15px; 
		max-width: 500px; 
		width: 100vw;		
		display: flex; 
		flex-direction: column; 
		justify-content: space-between; 
		padding: 15px 15px 30px 15px;  
		box-sizing: content-box !important;
	}
	.form-message {
		font-size: 26px;
	}
	.footer {
		box-sizing: border-box;
		background: #555;
		width: 100%;
		display: flex;
		display: -webkit-flex;
		display: -webkit-flexbox;
		flex-direction: column;
		-webkit-flex-direction: column;
		justify-content: space-between;
		align-items:center;
		margin: 0;
		padding: 15px 30px;
		margin-top: 15px;
	}
	.footer-contents {
		display: flex;
		display: -webkit-flex;
		display: -webkit-flexbox;
		justify-content: space-between;
		flex: 1;
		-webkit-flex: 1;
		color: white;
		width: 80%;
	}
	.footer-contents > ul > li {
		padding: 5px;
	}
	.footer-grid {
		display: grid;
		grid-template-columns: 0.5fr 1fr;		
	}
	.footer-grid > :nth-child(odd) {
		font-weight:bold;
		text-align: right;
		padding-right: 10px;
	}
	li > a {
		color: white;
		text-decoration: none;
	}
	.feedback {
		display: flex;
		flex-direction: column;
		width: 20vw;
	}
	.activity-sm {
		height: 25px;		
		margin: 5px 15px;
		grid-template-columns: 7px 7px 7px;
		grid-column-gap: 2px;
		grid-row-gap: 2px;			
	}
	.activity {		
		height: 170px;
		margin: 20px 0;
		grid-template-columns: 50px 50px 50px;
		grid-column-gap: 10px;
		grid-row-gap: 10px;		
	}
	.activity, .activity-sm {
		display: grid;
		justify-content: center;
		align-items: center;	
	}
	.activity > div {
		width: 50px;
		height: 50px;	
		border-radius: 0%;
		 -webkit-animation: activity 1s ease-out forwards;
	  animation: activity 1.6s ease infinite;  
	}
	
	.activity-sm > div {
		width: 7px;
		height: 7px;	
		border-radius: 0%;
		 -webkit-animation: activity 1s ease-out forwards;
	  animation: activity 1.6s ease infinite;  
	}

	.activity > div:nth-child(5), .activity-sm > div:nth-child(5) {
		background-color: rgb(128, 43, 177);
		animation: none;
	}
	.activity > div:nth-child(2), .activity-sm > div:nth-child(2) {
		animation-delay: 0.2s;
	}
	.activity > div:nth-child(3), .activity-sm > div:nth-child(3) {
		animation-delay: 0.4s;
	}
	.activity > div:nth-child(6), .activity-sm > div:nth-child(6) {
		animation-delay: 0.6s;
	}
	.activity > div:nth-child(9), .activity-sm > div:nth-child(9) {
		animation-delay: 0.8s;
	}
	.activity > div:nth-child(8), .activity-sm > div:nth-child(8) {
		animation-delay: 1s;
	}
	.activity > div:nth-child(7), .activity-sm > div:nth-child(7) {
		animation-delay: 1.2s;
	}
	.activity > div:nth-child(4), .activity-sm > div:nth-child(4) {
		animation-delay: 1.4s;
	}
	
}
@media only screen and (min-width:1024px and max-width: 1346px) {
	.kids, .men {	
		width: 35vw;
	}
}
@media only screen and (min-width:1024px) {
	.fullscreen {
		width: 100vw;
		height: 100vh;
		display: flex;
		justify-content: center;
		align-items: center;
		background-color: #fff;
	}
	.header {
		position: sticky;
		top: 200px;
		z-index: 2;
		background-color: #f0ffff;
		display: flex;
		flex-direction: column;
		justify-content: flex-end;
		align-items: center;		
		margin-bottom:5px;
		box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;

	}
	.logo {
		width: 50px;
		height: 32px;
		padding: 0;
		margin-left: 30px;
		cursor: pointer;
	}
	.search {
		padding: 10px 30px 5px 30px;
		position: relative;
		display: flex;
		align-items: center;
		color: #f0ffff;
		width: 70%;
	}
	.search > input {
		border-radius: 4px;
		width: 100%;
		height: 30px;		
		border: 0.5px solid #e9e9e9;
		color: #f0ffff;
		padding: 0 15px;
		box-sizing: border-box;
		box-shadow: rgba(115, 149, 174, 0.2) 0px 2px 8px 0px;
	}
	
	.search > svg {
		position: absolute;
		color: #aaa;
		font-weight: bold;
		width: 20px;
		height: 20px;
		padding: 5px;
		right: 35px;		
	}
	#home-message {
		display: flex; 
		padding: 15px 0px 15px 30px;
		box-sizing: border-box;
	}
	.side-menu {
		border: 1px solid #aaa;
		border-radius: 5px;
		padding: 10px 30px;
		width: 200px;
		max-height: 405px;
		overflow: hidden;
	}
	#home-message > div.home-pic {
		flex: 1;
		padding: 0 0 0 30px;
	}
	.deals-container {
		
		grid-gap: 30px;
		padding: 15px 0 15px 30px;
		box-sizing: border-box;
		max-width: 100vw;
		box-sizing: border-box;
	}
	.electronics-items {
		margin: 15px;
		width: calc(75vw - 60px);
	}
	.deals-category {
		padding: 0 10px 10px;		
		background-color: #fff;
		display: flex;
		flex-direction: column;
		border-radius: 3px;
		
		box-sizing: border-box;
	}
	.deals-grid {
		padding: 5px;
		height: 300px; 
	}
	.home-bg, .highlight {
		height: 80% !important;
	}
	.deals-grid > div > div {
		height: 20%;
	}
	.deals-grid > div.home-activity {
		width: calc(20vw - 48px);
		min-width: calc(20vw - 48px);
		max-width: calc(20vw - 48px);		 
	} 
	.pic-protype {
		width: 16px;
		height: 16px;
		border-radius: 50%;
		background-color: #000;
		margin-left: 5px;
		background-repeat: no-repeat;
		background-size: 100%;		
	}
	.list-container {
		width: 100%;
		padding-right: 15px;
		box-sizing: border-box;
		display: grid;
		grid-template-columns: 1fr 1fr 1fr 1fr;
		grid-column-gap: 30px;
		grid-row-gap: 30px;
	}
	.list-picture {
		height: 25vw;
		box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
		
	}
	.list-picture-expanded {
		height: 400px;
		min-height: 300px;
		margin: 15px;
		box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;			
	}	
	.cat-list-container {
		display: flex;
		height: 100%;
		width: 100%;
		padding: 0 30px;
		box-sizing: border-box;
	}
	.cat-list-category {
		 flex: 1;
		 padding: 0 30px;
	}
	.cat-title {
		width: calc(100% - 30px);
		padding: 15px 30px;
		margin: 5px 15px 25px 15px;
		font-weight: bold;
		border-radius: 4px;
		font-size: 26px;
		box-sizing: border-box;
		background-color: rgb(128, 43, 177, 0.7);
		color: #fff;
		box-sizing: border-box;
	}
	.kids, .men {	
		width: 35vw;
	}
	.pic-city {
		display: none;
	}
	.cat-list {
		display: flex;
		flex-direction: column;
		padding: 10px;
		border-radius: 5px;
		margin: 25px 15px;
		background-color: #ffffff;
		border: 1px solid rgb(128, 43, 177,0.2);
	}
	.home-ad {
		width: 100%;
		grid-column: 1/4;
		height: 225px;
		background-color: #fff;
	}
	.list-ad {
		width: 300px;
		min-height: calc(75vh - 10px);
		background-color: rgb(128, 43, 177, 0.2);
		border-top-right-radius: 4px;
		border-top-left-radius: 4px;
	}
.description-icon {
	width: 30px; 
	height: 30px;
	margin: 10px;
}
	.form-container {
		z-index: 5;  
		background-color: #ccc; 
		font-family: Montserrat; 
		margin: 30px 15px; 
		max-width: 400px; 
		width: 100vw; 		
		display: flex; 
		flex-direction: column; 
		justify-content: space-between; 
		padding: 15px 15px 30px 15px;  
		box-sizing: content-box !important;
	}
	.form-message {
		font-size: 26px;
	}
	.footer {
		box-sizing: border-box;
		background: #555;
		width: 100%;
		display: flex;
		display: -webkit-flex;
		display: -webkit-flexbox;
		flex-direction: column;
		-webkit-flex-direction: column;
		justify-content: space-between;
		align-items:center;
		margin: 0;
		padding: 15px 30px;
		margin-top: 15px;
	}
	.footer-contents {
		display: flex;
		display: -webkit-flex;
		display: -webkit-flexbox;
		justify-content: space-between;
		flex: 1;
		-webkit-flex: 1;
		color: white;
		width: 80%;
	}
	.footer-contents > ul > li {
		padding: 5px;
	}
	.footer-grid {
		display: grid;
		grid-template-columns: 0.5fr 1fr;		
	}
	.footer-grid > :nth-child(odd) {
		font-weight:bold;
		text-align: right;
		padding-right: 10px;
	}
	li > a {
		color: white;
		text-decoration: none;
	}
	.feedback {
		display: flex;
		flex-direction: column;
		width: 20vw;
	}
	.activity-sm {
		height: 25px;		
		margin: 5px 15px;
		grid-template-columns: 7px 7px 7px;
		grid-column-gap: 2px;
		grid-row-gap: 2px;			
	}
	.activity {		
		height: 170px;
		margin: 20px 0;
		grid-template-columns: 50px 50px 50px;
		grid-column-gap: 10px;
		grid-row-gap: 10px;		
	}
	.activity, .activity-sm {
		display: grid;
		justify-content: center;
		align-items: center;	
	}
	.activity > div {
		width: 50px;
		height: 50px;	
		border-radius: 0%;
		 -webkit-animation: activity 1s ease-out forwards;
	  animation: activity 1.6s ease infinite;  
	}
	
	.activity-sm > div {
		width: 7px;
		height: 7px;	
		border-radius: 0%;
		 -webkit-animation: activity 1s ease-out forwards;
	  animation: activity 1.6s ease infinite;  
	}

	.activity > div:nth-child(5), .activity-sm > div:nth-child(5) {
		background-color: rgb(128, 43, 177);
		animation: none;
	}
	.activity > div:nth-child(2), .activity-sm > div:nth-child(2) {
		animation-delay: 0.2s;
	}
	.activity > div:nth-child(3), .activity-sm > div:nth-child(3) {
		animation-delay: 0.4s;
	}
	.activity > div:nth-child(6), .activity-sm > div:nth-child(6) {
		animation-delay: 0.6s;
	}
	.activity > div:nth-child(9), .activity-sm > div:nth-child(9) {
		animation-delay: 0.8s;
	}
	.activity > div:nth-child(8), .activity-sm > div:nth-child(8) {
		animation-delay: 1s;
	}
	.activity > div:nth-child(7), .activity-sm > div:nth-child(7) {
		animation-delay: 1.2s;
	}
	.activity > div:nth-child(4), .activity-sm > div:nth-child(4) {
		animation-delay: 1.4s;
	}
	
}
@media only screen and (min-width: 768px) {
	.men {
		display: none !important;
	}
	.electronics-list {
		grid-template-columns: 1fr 1fr !important;
	}
	.cars-houses {
		height: 420px;
		grid-template-columns: 1fr 1fr 1fr;
	}
	.cars-houses.grid-2b2 > div:first-child {
		background: no-repeat center/cover url('../img/houses-and-cars_md.JPG');
	}
	.health-beauty {
		grid-template-columns: 1fr 1fr;
	}
	.home2 > div:first-child {
		background: no-repeat center/cover url('../img/home2_image_sm.JPG');
		width: 100%;
		max-width: 100%;
		animation: none !important;
	}
	.electronics-items {
		margin: 15px;
		width: calc(75vw - 145px);
	}
}
@media only screen and (min-width: 872px) {
	.electronics-list {
		grid-template-columns: 1fr 1fr 1fr  !important;
	}
	.electronics-items {
		margin: 15px;
		width: calc(75vw - 120px);
	}
}
@media only screen and (min-width: 1024px) {
	.men {
		display: flex !important;
	}
	.electronics-items {
		margin: 15px;
		width: calc(75vw - 90px);
	}
	.grid-1b2.electronics-list {
		grid-template-columns: 1fr 1fr 1fr 1fr !important;
	}
	.cars-houses {
		height: 420px;
		grid-template-columns: 1fr 1fr 1fr 1fr;
	}
	.cars-houses.grid-2b2 > div:first-child {
		background: no-repeat center/cover url('../img/houses-and-cars_md.JPG');
	}
	.health-beauty {
		grid-template-columns: 1fr 1fr;
	}
	.home2 > div:first-child {
		background: no-repeat center/cover url('../img/home2_image_md.JPG');
		animation: none !important;
	}
}
@media only screen and (min-width: 1346px) {
	.kids {
		display: flex !important;
	}
	.electronics-items {
		margin: 15px;
		width: calc(75vw - 60px);
	}
	.grid-1b2.electronics-list {
		grid-template-columns: repeat(5, 1fr) !important;
	}
	.cars-houses {
		height: 420px;
		grid-template-columns: 2fr 1fr 1fr 1fr;
	}
	.health-beauty {
		grid-template-columns: 1fr 1fr 1fr;
	}
	.cars-houses.grid-2b2 > div:first-child {
		background: no-repeat center/cover url('../img/houses-and-cars.JPG');
	}
	.home2 > div:first-child {
		background: no-repeat center/cover url('../img/home2_image2.JPG');
		animation: none !important;
	}
}
@media only screen and (min-width:1346px) {
	.deals-grid {
		 display: grid;
		 grid-template-columns: 1fr 1fr 1fr;
	}
}
#side-info {
	width: 100%;
	display: flex;
	z-index: 50;
	align-items: center;
	justify-content: center;
}
.side-info-container {
	width: 100%; 
	display: grid; 
	grid-template-columns: repeat( auto-fit, minmax(25%, 1fr));
	padding: 0 5px; 
	height: 50px;
	min-height: 60px;
	max-height: 60px;
	justify-content: center; 
	align-items: start;  
	margin: 5px 0 15px 0; 
	z-index: 50;
position: relative;	
}
.side-info-container > div {
	display :flex;	
	justify-content : flex-start;  
	flex-direction :column; 
	align-items :center;
	border-radius :5px; 
	overflow :hidden; 
	padding :5px;
}
.side-info-icon {
	position: relative; 
	overflow: hidden; 
	width: 30px; 
	height: 30px; 
	max-width: 30px; 
	max-height: 30px;
	min-width: 30px; 
	min-height: 30px;
	margin: 5px; 
	border-radius: 5px; 
	background-color: rgb(247, 136, 136); 
	color: #fff;  
	box-sizing: border-box; 
	flex-basis: 50%;
}
.side-info-text {	
	flex-basis: 50%; 
	width: 100%; 
	text-align: center;
	font-size: 12px; 
	color: #555;
}
.list-picture-expanded.unclicked {	
width: 60vw;height: 60vw;
object-fit: contain;
}
.hide {
	display: none;
}
.feedback {
		max-width: 400px;
	}
.invisible {
visibility: hidden;
}
.deals-grid {
    -ms-overflow-style: none;  /* Internet Explorer 10+ */
    scrollbar-width: none;  /* Firefox */

}
.deals-grid::-webkit-scrollbar { 
    display: none;  /* Safari and Chrome */
}
.form {
		font-family: Montserrat;
		padding: 5px !important;
		border: 1px solid rgb(0, 0, 0, 0.5);
		border-radius: 4px;
		margin: 10px 0;
		min-height: 20px;
	}
	textarea.form {
		min-height: 100px;
		height: 50px;
	}
	.upload-text {
		margin-top: 10px;
		color: #000;
	}
	input[type="file"].form {
		color: #000;
	}
	.home-image {
		position: relative; 
		border: 1px solid #ccc; 
		height: 100%; 
		box-sizing: border-box;
				
	}
	.home-image-anim {
		display: flex;
		position: relative;
		white-space: no-wrap;
	}
	.home-image-anim > div:first-child {
		width: 100vw;
		position: absolute;
		top:0;
		bottom: 0;
		left:0;		
		height: 100%;
		background: no-repeat center/cover url('../img/home_image.jpg');
	}
	.home-image-anim.home2 > div:first-child {		
		animation: none !important;
	}
	.home-image-anim > div:nth-child(2) {
		width: 100vw;
		position: absolute;
		top: 0;
		bottom: 0;
		left: 100vw;
		height: 100%;
		background: no-repeat center/cover url('../img/home_image2.JPG');
	}	
	/* .home-image-anim > div:first-child {		
		animation-name: home-image;
		animation-duration: 10s;
		animation-timing-function: ease-out;
		animation-iteration-count: infinite;
		animation-delay: 0s;
	} 
	.home-image-anim > div:nth-child(2) {		
		animation-name: home-image2;
		animation-duration: 10s;
		animation-timing-function: ease-out;
		animation-iteration-count: infinite;
		animation-delay: 0s;
	} */
	.health-beauty > div:first-child {
		grid-column: 1/3;
		width: auto !important;		
	}
	
	/* .home-image-anim > div:nth-child(2) {		
		animation-name: home-image;
		animation-duration: 5s;
		animation-timing-function: cubic-bezier(1,-1, 0, 2);
		animation-iteration-count: infinite;
		
		animation-delay: 10s;
		-webkit-animation-name: home-image;
		
		-webkit-animation-timing-function: cubic-bezier(1,-1, 0, 2);
		-webkit-animation-iteration-count: infinite;
	}   */
.cars-houses > div:first-child {
		grid-row: 1/3;
		width: auto !important;
		height: auto !important;
		background-color: #444 !important;
	}
.loading {
    position: relative;
	color: transparent;
	font-size: 14px;
}
.loading:after {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    transform: translateX(-100px);
    background: linear-gradient(90deg, transparent, rgba(255,255,255,0.2), transparent);
    animation: loading 0.8s infinite;
}
@keyframes loading {
    100% {
        transform: translateX(100%);
    }

}


@keyframes activity {
    0% {
        background-color:#eee;
    }
	15% {
        background-color:#eee;
    }
	50% {
        background-color:rgb(128, 43, 177);
    }
	85% {
        background-color:#eee;
    }
	100% {
        background-color:#eee;
    }
}
@-webkit-keyframes activity {
    0% {
        background-color:#eee;
    }
	15% {
        background-color:#eee;
    }
	50% {
        background-color:rgb(128, 43, 177);
    }
	85% {
        background-color:#eee;
    }
	100% {
        background-color:#eee;
    }
}